<template>
    <div v-if="!isPageVisible">
        <b-card no-body style="height: 150px" class="d-flex justify-content-center align-items-center">
            <h3>Resource Not Available</h3>
        </b-card>
    </div>
    <div v-else-if="user_type === 'agent'">
        <b-card no-body style="height: 150px" class="d-flex justify-content-center align-items-center">
            <h3>"Previledge access only to Aliff Franchise."</h3>
        </b-card>
    </div>
    <b-card v-else style="background-color: #fcfcfc">


        <div v-if="utilities.length > 0">
            <b-card class="border p-2" v-for="(material, index) in utilities" :key="'utilities_' + index"
                style="background-color: white">
                <h3>{{ material.fm_name }}</h3>
                <div v-html="material.fm_text" class="mt-2" style="
                  border-radius: 1rem;
                  padding: 2% 2% 2% 2%;
                  border-color: #000;
                  border-width: 1px;
                  border-style: solid;
                "></div>
            </b-card>
        </div>
        <template v-else>
            <h4 class="pl-2">No Available Utility.</h4>
        </template>

    </b-card>
</template>

<script>
import {
    BCard, BTab, BTabs, BCardText,
    BInputGroup,
    BFormInput,
} from "bootstrap-vue";
import AgentServices from "@/apiServices/AgentServices";
import CommonServices from "@/apiServices/CommonServices";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { WEBSITE_URL, FILESURL } from "@/config";

export default {
    components: {
        BCard, AppCollapse, AppCollapseItem, BTab, BTabs, BCardText,
        BInputGroup,
        BFormInput,
    },
    data() {
        return {
            collaterals: [],
            industry_news: [],
            blogs: [],
            materials: [],
            user_type: null,
            fact_sheets: null,
            utilities: [],
            WEBSITE_URL,
            FILESURL,
            search: '',
            debounce: null
        };
    },
    computed: {
        isPageVisible() {
            const user_details = this.$store.getters["user/getUserDetails"];
            this.user_type = user_details.user_type;
            if (user_details && Array.isArray(user_details.allocated_resources)) {
                const allowcatedResource = user_details.allocated_resources.find(
                    (elem) => elem === "marketing_insight"
                );
                if (allowcatedResource) {
                    if (this.user_type === 'franchise' || this.user_type === 'agent') {
                        this.getFranchiseIndustryNews()
                        this.getFranchiseMaterial()
                    } else {
                        this.getCounselorNews()
                        this.getAllMaterial()
                    }
                    return true;
                }
                return false;
            }
            return false;
        },
    },
    methods: {
        async getFranchiseMaterial() {
            try {
                const response = await AgentServices.getFranchiseMaterial();
                this.materials = response.data.data;
            } catch (error) {
                console.error(`Error in getFranchiseMaterial`);
            }
        },
        async getAllBlogs() {
            try {
                const response = await CommonServices.getAllBlogs();
                this.blogs = response.data.data;
            } catch (error) {
                console.error(`Error in getAllBlogs`);
            }
        },
        async getFranchiseIndustryNews() {
            try {
                const response = await AgentServices.getFranchiseIndustryNews();
                this.industry_news = response.data.data;
            } catch (error) {
                console.error(`Error in getFranchiseIndustryNews`);
            }
        },
        async getCounselorNews() {
            try {
                const response = await CommonServices.getAllIndustryNews();
                this.industry_news = response.data.data;
            } catch (error) {
                console.error(`Error in getFranchiseIndustryNews`);
            }
        },
        async getAllMaterial() {
            try {
                const response = await CommonServices.getAllMaterial();
                this.materials = response.data.data;
            } catch (error) {
                console.error(`Error in getFranchiseMaterial`);
            }
        },
        async getCountryFactSheet() {
            try {
                const response = await CommonServices.getCountryFactSheet();
                this.fact_sheets = response.data.data;
            } catch (error) {
                console.error(`Error in getCountryFactSheet`);
            }
        },
        async getUtilityDetails(ut_id) {
            try {
                const response = await CommonServices.getSingleUtility(ut_id)
                if (response.data.status) {
                    this.utilities = response.data.data
                }
            } catch (error) {
                console.error(`Error in getUtilityDetails`, error);
            }
        }
    },
    beforeMount() {
        const { ut_id } = this.$route.params;
        if (ut_id) {
            this.getUtilityDetails(ut_id)
        }
    },
};
</script>

<style>
.dashboard__heading {
    color: #002884;
    font-weight: bold;
    margin-bottom: 1.2rem;
}

.student-dashboard .card-title {
    color: #000000;
    font-weight: 600;
}

.primary-color {
    color: #00d09c;
}

.min-height {
    min-height: 60vh;
}
</style>